import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import Button from "../Button";

const FeaturedProjects = (props) => {
  let projects = "";

  if (props.projects !== undefined) {
    projects = (
      <ul
        className={cx([
          "featured-projects",
          "block sm:flex sm:justify-start sm:items-start flex-wrap",
        ])}
      >
        {props.projects.map((p) => {
          return (
            <li
              key={p.name}
              className={cx([
                "opacity-100 hover:opacity-70",
                "transition duration-300",
                "w-full sm:w-[30.333%]",
                "ml-[0] sm:ml-[3%] mb-[50px] sm:mb-[3%]",
              ])}
            >
              <NavLink to={"/work/" + p.id}>
                <img
                  src={p.thumbnail}
                  alt=""
                  className={cx([
                    "thumbnail",
                    "mb-[10px] sm:mb-[15px]",
                    "object-cover",
                  ])}
                />
                <h3 className={cx(["text=[16px]"])}>{p.name}</h3>
              </NavLink>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div
      className={cx([
        "other-projects",
        "pt-[40px] pb-[40px] md:pb-[60px]",
        "block md:flex md:justify-between md:items-start",
      ])}
    >
      <div
        className={cx([
          "w-full md:w-[25%]",
          "pr-[0] md:pr-[5%] mb-[20px] md:mb-[0]",
        ])}
      >
        <h2
          className={cx([
            "mb-[15px] md:mb-[30px]",
            "leading-[1.17] text-black font-500 text-[22px] md:text-[28px]",
          ])}
        >
          Featured projects
        </h2>

        <Button link="/work" exact title="SEE MORE" black />
      </div>
      <div className={cx(["w-full md:w-[75%]"])}>{projects}</div>
    </div>
  );
};

export default FeaturedProjects;
