import React from "react";
import _ from "lodash";
import cx from "classnames";

import MainHeading from "../MainHeading";
import FeaturesList from "./FeaturesList";

const Overview = (props) => {
  return (
    <div className="page-width">
      <MainHeading title={_.get(props.data, ["headline"], "")} large />
      <div className={cx(["w-full md:w-[75%] md:ml-auto text-white"])}>
        <FeaturesList feature={props.feature} />
      </div>
    </div>
  );
};

export default Overview;
