import React, { useState, useEffect } from "react";
import _ from "lodash";
import cx from "classnames";
import Button from "../Button";

import MainHeading from "../MainHeading";

const ExploreProjects = (props) => {
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth, setHeight]);

  useEffect(() => {
    const update = (e) => {
      setX(e.x);
      setY(e.y);
    };

    window.addEventListener("mousemove", update);
    window.addEventListener("touchmove", update);
    return () => {
      window.removeEventListener("mousemove", update);
      window.removeEventListener("touchmove", update);
    };
  }, []);

  useEffect(() => {
    setIsMobile(width <= 1024);
  }, [width]);

  const imgWrapperCommonStyle = {
    position: "relative",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "4s all cubic-bezier(.17,.67,.56,1.16)",
  };

  const images = [
    {
      imgURL: "/images/projects-photo01.jpg",
      wrapperStyle: {
        gridArea: isMobile ? "1 / 16 / 9 / 26" : "1 / 17 / 9 / 26",
        transitionDuration: "4.2s",
        transform: `translate(${(x - width / 2) * 0.03}px, ${
          (y - height / 2) * 0.03
        }px)`,
      },
      imgStyle: {},
    },
    {
      imgURL: "/images/projects-photo02.jpg",
      wrapperStyle: {
        gridArea: isMobile ? "40 / 10 / 50 / 30" : "39 / 25 / 51 / 31",
        transform: `translate(${(x - width / 2) * 0.04}px, ${
          (y - height / 2) * 0.04
        }px)`,
      },
      imgStyle: {},
    },
    {
      imgURL: "/images/projects-photo03.jpg",
      wrapperStyle: {
        gridArea: isMobile ? "30/ 1 / 38 / 15" : "36 / 12 / 45 / 22",
        transitionDuration: "4.2s",
        transform: `translate(${(x - width / 2) * 0.02}px, ${
          (y - height / 2) * 0.02
        }px)`,
      },
      imgStyle: { left: isMobile && "-15%" },
    },
    {
      imgURL: "/images/projects-photo04.jpg",
      wrapperStyle: {
        gridArea: isMobile ? "3 / 30/ 14 / 50" : "2 / 33 / 13 / 45",
        transform: `translate(${(x - width / 2) * 0.025}px, ${
          (y - height / 2) * 0.025
        }px)`,
      },
      imgStyle: {},
    },
    {
      imgURL: "/images/projects-photo05.jpg",
      wrapperStyle: {
        gridArea: "35 / 37 / 45 / 48",
        transitionDuration: "4.2s",
        transform: `translate(${(x - width / 2) * 0.02}px, ${
          (y - height / 2) * 0.02
        }px)`,
      },
      imgStyle: {},
    },
    {
      imgURL: "/images/projects-photo06.jpg",
      wrapperStyle: {
        display: isMobile && "none",
        gridArea: "12 / 8 / 30 / 15",
        transform: `translate(${(x - width / 2) * 0.04}px, ${
          (y - height / 2) * 0.04
        }px)`,
      },
      imgStyle: {},
    },
    {
      imgURL: "/images/projects-photo07.jpg",
      wrapperStyle: {
        gridArea: isMobile ? "10 / 1 / 21 / 14" : "8 / 1 / 22 / 10",
        transitionDuration: "4.2s",
        transform: `translate(${(x - width / 2) * 0.03}px, ${
          (y - height / 2) * 0.03
        }px)`,
      },
      imgStyle: { left: isMobile ? "-5%" : "-50%" },
    },
    {
      imgURL: "/images/projects-photo08.jpg",
      wrapperStyle: {
        display: isMobile && "none",
        gridArea: "18 / 40 / 28 / 50",
        transform: `translate(${(x - width / 2) * 0.04}px, ${
          (y - height / 2) * 0.04
        }px)`,
      },
      imgStyle: { right: "-50%" },
    },
    {
      imgURL: "/images/projects-photo09.jpg",
      wrapperStyle: {
        display: isMobile && "none",
        gridArea: "40 / 1 / 50 / 10",
        transitionDuration: "4.2s",
        transform: `translate(${(x - width / 2) * 0.03}px, ${
          (y - height / 2) * 0.03
        }px)`,
      },
      imgStyle: { bottom: "-40%", left: "-50%" },
    },
    {
      imgURL: "/images/projects-photo10.jpg",
      wrapperStyle: {
        gridArea: isMobile ? "22 / 36 / 28 / 50" : "19 / 34 / 32 / 40",
        transform: `translate(${(x - width / 2) * 0.02}px, ${
          (y - height / 2) * 0.02
        }px)`,
      },
      imgStyle: { right: isMobile && "-15%" },
    },
  ];

  const centerBoxStyle = {
    gridArea: isMobile ? "15 / 1 / 35 / 50" : "15/ 10 / 35 / 40",
  };

  return (
    <div className={cx(["grid"])}>
      {images.map((img, i) => {
        return (
          <div
            key={i}
            style={{
              ...imgWrapperCommonStyle,
              ...img.wrapperStyle,
            }}
          >
            <div
              className={cx([
                "grid-item-image",
                "w-full h-full relative",
                "rounded-[8px]",
                "bg-center bg-no-repeat bg-cover",
              ])}
              alt=""
              style={{
                backgroundImage: `url(${img.imgURL})`,
                ...img.imgStyle,
              }}
            />
          </div>
        );
      })}
      <div
        className={cx([
          "page-width",
          "!px-[7%] sm:!px-[10%]",
          "flex justify-center items-center",
          "z-[2]",
        ])}
        style={centerBoxStyle}
      >
        <div className="w-full">
          <div className={cx(["w-full", "z-[1]"])}>
            <MainHeading
              title="Projects"
              medium
              gradientOutline
              gradientPurple={process.env.REACT_APP_IS_EOSSITE === "TRUE"}
              customStylesOutline="text-[#1c3ba1] w-full text-center"
              customStylesTitle={`${
                process.env.REACT_APP_IS_EOSSITE === "TRUE"
                  ? "!text-black"
                  : "!text-white"
              } text-center md:!top-[60%]`}
            />
          </div>
          <p
            className={cx([
              "max-w-[375px]",
              "ml-[auto] mr-[auto] mb-[20px] mt-[20px] md:mt-[-25px]",
              "text-black text-center",
              "relative z-[2]",
            ])}
          >
            {_.get(props.data, ["strapline"], "")}
          </p>
          <div className={cx(["flex justify-center items-center"])}>
            <Button
              link={_.get(props.data, ["button_link"], "")}
              effect
              exact
              title={_.get(props.data, ["button_name"], "")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreProjects;
