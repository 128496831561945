import React from "react";
import _ from "lodash";
import cx from "classnames";

import MainHeading from "../MainHeading";
import Button from "../Button";

const Introduction = (props) => {
  return (
    <div className="page-width">
      <MainHeading title={_.get(props.data, ["headline"], "")} large />
      <div
        className={cx(["w-full md:w-[50%] md:ml-auto text-white", "relative"])}
      >
        <p
          className={cx([
            "mb-[7.69vw] sm:mb-[5.99vw] md:mb-[5vh] lg:mb-[40px]",
            "text-white leading-[normal] whitespace-pre-wrap",
            "fluid-text-24px",
          ])}
        >
          {_.get(props.data, ["strapline"], "")}
        </p>
        <Button
          link={_.get(props.data, ["button_link"], "")}
          exact
          title={_.get(props.data, ["button_name"], "")}
        />
      </div>
    </div>
  );
};
export default Introduction;
