import React from "react";
import cx from "classnames";

const ClientLogoListItem = (props) => {
  return (
    <li
      className={cx([
        "marquee-item",
        "pr-[1%] pl-[1%]",
        "flex justify-center items-center",
      ])}
    >
      <img
        className={cx(["w-full max-h-[70px] sm:max-h-[100px] object-contain"])}
        src={props.logoImage}
        alt=""
      />
    </li>
  );
};

export default ClientLogoListItem;
