import React from "react";
import cx from "classnames";

const Description = (props) => {
  return (
    <div
      className={cx([
        "description",
        "pt-[40px] md:pt-[70px] pb-[40px] md:pb-[70px]",
        "block md:flex md:justify-between md:items-start",
        "border-b-[1px] border-[black] border-solid",
      ])}
    >
      <div
        className={cx([
          "w-full md:w-[50%]",
          "pr-[0] md:pr-[10%] mb-[20px] md:mb-[0]",
        ])}
      >
        <h2
          className={cx([
            "leading-[1.17] text-black font-500 text-[28px] md:text-[36px]",
          ])}
        >
          {props.title}
        </h2>
      </div>

      <div
        className={cx([
          "w-full md:w-[50%]",
          "text-black leading-[1.5] font-[16px] whitespace-pre-wrap",
        ])}
      >
        {props.overview}
      </div>
    </div>
  );
};

export default Description;
