import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const FeaturesListItem = (props) => {
  const button = (
    <span
      className={cx([
        "pb-[7px]",
        "border-b-[1px] border-[white] border-solid",
        "text-white leading-[1] text-[10px] sm:text-[14px] md:text-[12px]",
      ])}
    >
      {props.button_name}
    </span>
  );

  return (
    <li
      className={cx([
        "features-list-item",
        "w-full sn:w-[33.3333%]",
        "sm:pr-[2%] sm:pl-[2%] mb-[12.8vw] sm:mb-[unset]",
      ])}
    >
      <div
        className={cx([
          "number",
          "mb-[5.12vw] sm:mb-[2.39vw] md:mb-[1.38vw] lg:mb-[20px]",
          "text-white font-bold leading-[1]",
          "fluid-text-64px",
        ])}
      >
        {props.number}
      </div>

      <div
        className={cx([
          "mb-[10px]",
          " text-white font-bold leading-[1]",
          "fluid-text-24px",
        ])}
      >
        {props.headline}
      </div>

      <div
        className={cx([
          "min-h-[unset] sm:min-h-[120px] md:min-h-[5.55vw] lg:min-h-[80px]",
          "mb-[5.12vw] sm:mb-[2.99vw] md:mb-[2.5vh] lg:mb-[25px]",
          "text-white font-bold leading-[1.43] text-[14px] md:text-[0.9vw] lg:text-[14px]",
        ])}
      >
        {props.strapline}
      </div>

      {props.button_link === "#awards" ? (
        <NavHashLink to={props.button_link}>{button}</NavHashLink>
      ) : (
        <NavLink to={props.button_link}>{button}</NavLink>
      )}
    </li>
  );
};

export default FeaturesListItem;
