import React from "react";
import _ from "lodash";
import cx from "classnames";
import Button from "../Button";
import ServiceList from "../ServiceList";
import MainHeading from "../MainHeading";

const Services = (props) => {
  return (
    <React.Fragment>
      <div
        className={cx([
          "page-width",
          "h-auto md:h-[40vh] min-h-[45vh] md:min-h-[unset] flex justify-center items-center",
          "pt-[25%] sm:pt-[15%] md:pt-[unset] pb-[20%] sm:pb-[15%] md:pb-[unset]",
          "md:!pr-[3.5%]",
        ])}
      >
        <div
          className={cx([
            "display md:flex md:justify-between md:items-center flex-wrap",
          ])}
        >
          <div
            className={cx([
              "w-full md:w-1/2",
              "mb-[7.69vw] sm:mb-[7.67vw] md:mb-[unset] ",
              "z-[0]",
            ])}
          >
            <MainHeading
              title={_.get(props.data, ["headline"], "")}
              medium
              customStylesOutline="ml-[-4vw] sm:ml-[-3.59vw] md:ml-[-3.47vw] lg:ml-[-50px]"
              customStylesTitle="md:!top-[53%]"
            />
          </div>

          <div
            className={cx([
              "w-full md:w-1/2",
              "mt-[unset] md:mt-[10px]",
              "relative",
            ])}
          >
            <p className={cx(["fluid-text-16px text-white leading-[1.5]"])}>
              {_.get(props.data, ["strapline"], "")}
            </p>
          </div>

          <div
            className={cx([
              "w-full md:w-1/2",
              "md:ml-auto mt-[20px] md:mt-0",
              "relative",
            ])}
          >
            <Button
              link={_.get(props.data, ["button_link"], "")}
              exact
              title={_.get(props.data, ["button_name"], "")}
            />
          </div>
        </div>
      </div>

      <div
        className={cx([
          "relative",
          "min-h-[55vh] md:min-h-[unset] h-[auto]] md:h-[60vh]",
        ])}
      >
        <ServiceList
          scroll={true}
          mouseEnterHandler={props.mouseEnterHandler}
        />
      </div>
    </React.Fragment>
  );
};

export default Services;
