import React from "react";
import cx from "classnames";

const SmallHeading = (props) => {
  return (
    <div
      className={cx([
        "relative w-auto",
        "flex justify-start items-center flex-wrap",
        "text-left",
      ])}
    >
      <div
        className={cx([
          "text-outline-effect",
          "whitespace-nowrap",
          "absolute top-0 left-0",
          "mt-[-6vw] sm:mt-[-4.19vw] md:mt-[-2.43vw] lg:mt-[-35px] ml-[-5vw] sm:ml-[-3.59vw] md:ml-[-2.08vw] lg:ml-[-30px]",
          "w-full md:w-[75%]",
          "leading-[1.1] text-white",
          "fluid-text-48px",
          props.customStylesOutline && props.customStylesOutline,
        ])}
      >
        {props.title}
      </div>
      <h2
        className={cx([
          "normal-text",
          "relative top-[0] left-[0]",
          "w-auto",
          "leading-[1] text-white",
          "fluid-text-24px",
          props.customStylesTitle && props.customStylesTitle,
        ])}
      >
        {props.title}
      </h2>
    </div>
  );
};

export default SmallHeading;
