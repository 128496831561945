import React from "react";
import cx from "classnames";

const AreasOfExpertise = (props) => {
  let areas = [];

  if (props.areas) {
    areas = props.areas.map((data) => {
      return (
        <div key={data.area} className={cx(["w-[100%]", ""])}>
          <h3
            className={cx(["mb-[10px] md:mb-[15px]", "font-800 text-[14px]"])}
          >
            {data.area}
          </h3>
          <ul className={cx(["mb-[30px]"])}>
            {data.labels.map((label, i) => {
              return (
                <li key={label.id} className={cx(["mb-[10px]", ""])}>
                  {label.attributes.label}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  }

  return <>{areas}</>;
};

export default AreasOfExpertise;
