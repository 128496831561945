import React from "react";
import cx from "classnames";
import logo1 from "../../assets/images/logo-1220.png";
import logo2 from "../../assets/images/logo-APA.png";
import logo3 from "../../assets/images/logo-BHOF.png";
import logo4 from "../../assets/images/logo-CambridgeSeven.png";
import logo5 from "../../assets/images/logo-Cinnabar.png";
import logo6 from "../../assets/images/logo-CityofBurnaby.png";
import logo7 from "../../assets/images/logo-CRTKL.png";
import logo8 from "../../assets/images/logo-PCL.png";
import logo9 from "../../assets/images/logo-rogers.png";
import logo10 from "../../assets/images/logo-SOULCYCLE.png";
import logo11 from "../../assets/images/logo-W3.png";
import logo12 from "../../assets/images/logo-YVR.png";
import logo13 from "../../assets/images/logo-kubik.png";

import ClientLogoListItem from "./ClientLogoListItem";

const ClientLogoList = () => {
  const data = [
    { logoImage: logo1 },
    { logoImage: logo2 },
    { logoImage: logo3 },
    { logoImage: logo4 },
    { logoImage: logo5 },
    { logoImage: logo6 },
    { logoImage: logo7 },
    { logoImage: logo8 },
    { logoImage: logo9 },
    { logoImage: logo10 },
    { logoImage: logo11 },
    { logoImage: logo12 },
    { logoImage: logo13 },
  ];

  return (
    <div className={cx(["marquee-wrapper", "flex"])}>
      <ul className={cx(["marquee1", "flex justify-between items-center"])}>
        {data.map(function (data, i) {
          return <ClientLogoListItem key={i} logoImage={data.logoImage} />;
        })}
      </ul>
      <ul className={cx(["marquee2", "flex justify-between items-center"])}>
        {data.map(function (data, i) {
          return <ClientLogoListItem key={i} logoImage={data.logoImage} />;
        })}
      </ul>
    </div>
  );
};

export default ClientLogoList;
