import React from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import TeamContainer from "../../components/Team/TeamContainer";

const Team = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Team &#8211; Eos Lightmedia</title>
      </Helmet>

      <TeamContainer />
    </React.Fragment>
  );
};

export default Team;
