import React from "react";
import _ from "lodash";
import cx from "classnames";

import FeaturesListItem from "./FeaturesListItem";

const FeaturesList = (props) => {
  return (
    <ul
      className={cx([
        "features-list",
        "block sm:flex justify-between items-start",
      ])}
    >
      {props.feature &&
        props.feature.map((f) => {
          return (
            <FeaturesListItem
              key={_.get(f, ["title"], "")}
              number={_.get(f, ["number"], "")}
              headline={_.get(f, ["title"], "")}
              strapline={_.get(f, ["description"], "")}
              button_link={_.get(f, ["link"], "")}
              button_name={_.get(f, ["link_name"], "")}
            />
          );
        })}
    </ul>
  );
};

export default FeaturesList;
