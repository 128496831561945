import React, { useState, useEffect } from "react";
import ScrollSnapSection from "../../components/ScrollSnapSection";
import backgroundImage1 from "../../assets/images/gradient_bg_02.jpg";
import ServiceList from "../../components/ServiceList";

import { useQuery } from "../../Utils/useQuery";
import { Helmet } from "react-helmet";

const Services = (props) => {
  const order = useQuery().get("order");
  const initialBackgroundImage = order >= 0 ? order % 4 : backgroundImage1;

  const [backgroundImage, setBackgroundImage] = useState(
    `${process.env.PUBLIC_URL}/images/${initialBackgroundImage}.jpg`
  );

  useEffect(() => {
    if (order) {
      setBackgroundImage(`${process.env.PUBLIC_URL}/images/${order % 4}.jpg`);
    }
  }, [order]);

  const updateBackgroundImage = (data) => {
    if (data === "") {
      setBackgroundImage(`${process.env.PUBLIC_URL}/images/${order % 4}.jpg`);
    } else {
      setBackgroundImage(data);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Services &#8211; Eos Lightmedia</title>
      </Helmet>

      <ScrollSnapSection
        backgroundImage={backgroundImage}
        showScrollButton={false}
        id="services"
        prevLocation={props.prevLocation}
      >
        <ServiceList
          isFullScreen
          scroll={false}
          isSingleServicePage={props.isSingleServicePage}
          mouseEnterHandler={updateBackgroundImage}
          mouseLeaveHandler={updateBackgroundImage}
        />
      </ScrollSnapSection>
    </React.Fragment>
  );
};

export default Services;
