import React, { useRef, useState, useEffect, useMemo } from "react";
import cx from "classnames";

import { useHistory } from "react-router";
import { useQuery } from "../Utils/useQuery";
import { useLocation } from "react-router";
import useWindowDimensions from "../Utils/useWindowDimensions";
import checkPath from "../Utils/checkPath";

import ServiceListItem from "./ServiceListItem";
import { fetchServices } from "../http";

const ServiceList = (props) => {
  const { width } = useWindowDimensions();
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const slug = history.location.pathname.replace("/services/", "");

  const tablet = 1024;
  const mobile = 640;
  const isSingleServicePage = checkPath(location.pathname, "/services/:slug")
    ? true
    : false;

  const [itemWidth, setItemWidth] = useState(0);
  const [numOfItemsDisplayed, setNumOfItemsDisplayed] = useState(1);
  const [carouselPositions, setCarouselPositions] = useState([]);

  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);

  const [services, setServices] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLeftPos, setCurrentLeftPos] = useState(0);

  const [hideButtons, setHideButtons] = useState(false);

  // Used for carousel on SERVICES page
  const [count, setCount] = useState(0);
  // Used for carousel on a SINGLE SERVICE page
  const [x, setX] = useState(0);

  /** Fetch services **/
  useEffect(() => {
    fetchServices().then((res) => {
      // Set the current index based on the slug
      setCurrentIndex(res.findIndex((s) => s.attributes.slug === slug));
      // Set the services.
      setServices(
        res.map((s, i) => {
          return { ...s, index: i };
        })
      );
    });
  }, [slug]);

  /** Set # of items per view **/
  useEffect(() => {
    if (width > tablet) {
      setNumOfItemsDisplayed(4);
      setHideButtons(services.length <= 4);
    } else if (width > mobile) {
      setNumOfItemsDisplayed(2);
      setHideButtons(services.length <= 2);
    } else {
      setNumOfItemsDisplayed(1);
      setHideButtons(services.length <= 1);
    }
  }, [services, width]);

  /**  Recalculate current index when slug changes **/
  useEffect(() => {
    setCurrentIndex(services.findIndex((s) => s.attributes.slug === slug));
  }, [services, slug]);

  /** Recalculate selected service when current index changes **/
  const selectedService = useMemo(
    () => services[currentIndex],
    [services, currentIndex]
  );

  /** Change query when selected service is not equal to slug  **/
  useEffect(() => {
    if (selectedService && selectedService.attributes.slug !== slug) {
      query.delete("order");
      query.append("order", currentIndex);
      history.replace({
        pathname: `/services/${selectedService.attributes.slug}`,
        search: `?${query.toString()}`,
      });
    }
  }, [selectedService]);

  /** set X (FOR SINGLE SERVICE PAGE) **/
  useEffect(() => {
    let newX =
      itemWidth &&
      Math.floor(
        getExactScrollLeftValue(ref.current.scrollLeft).value / itemWidth
      );

    if (width > tablet) {
      if (newX < currentIndex - 3) {
        newX = newX + 1;
      } else if (newX > currentIndex) {
        newX = newX - 1;
      } else {
        newX = newX;
      }
    } else if (width > mobile) {
      if (newX < currentIndex - 1) {
        newX = newX + 1;
      } else if (newX > currentIndex) {
        newX = newX - 1;
      } else {
        newX = newX;
      }
    } else {
      newX = currentIndex;
    }

    setX(newX);
  }, [services, selectedService, currentLeftPos]);

  /** get current scroll left  **/
  useEffect(() => {
    setCurrentLeftPos(getExactScrollLeftValue(ref.current.scrollLeft).value);
  }, [carouselPositions]);

  /** on scroll **/
  useEffect(() => {
    ref.current.addEventListener("scroll", scrollHandler);
    return () => {
      ref.current.removeEventListener("scroll", scrollHandler);
    };
  }, [carouselPositions]);

  /** on resize **/
  useEffect(() => {
    setCarouselPositions(getCarouselPositions);
  }, [width]);

  const scrollHandler = () => {
    const leftPos = getExactScrollLeftValue(ref.current.scrollLeft);
    leftPos.foundValue && setCurrentLeftPos(leftPos.value);
  };

  /** carousel (FOR SINGLE SERVICE PAGE) **/
  useEffect(() => {
    if (isSingleServicePage) {
      ref.current.scrollTo({
        left: itemWidth * x,
        behavior: btnClicked ? "smooth" : "auto",
      });
    }
  }, [x]);

  /** Update count when current left position changes (FOR SERVICES PAGE) **/
  useEffect(() => {
    if (!isSingleServicePage) {
      if (carouselPositions.length > 0) {
        const exactLeftPos = getExactScrollLeftValue(currentLeftPos).value;
        const i = carouselPositions.indexOf(
          carouselPositions.find((c) => c[0] === exactLeftPos)
        );

        if (i === -1) return;

        // when scrolled horizontally, change count which triggers scrollTo behaviour
        //e.g) the second item is at leftmost but count is 0, meaning that a user scroll to right => should increment count
        if (i > count) {
          setCount(count + 1);
        }
        //e.g) the first item is at leftmost but count is one, meaning that a user scroll to right => should decrement count
        if (i < count) {
          setCount(count - 1);
        }
      }
    }
  }, [currentLeftPos]);

  /** carousel (FOR SERVICES PAGE)**/
  useEffect(() => {
    ref.current.scrollTo({
      left: itemWidth * count,
      behavior: "smooth",
    });
  }, [count]);

  /** disable/enable buttons (FOR SERVICES PAGE) **/
  useEffect(() => {
    if (!isSingleServicePage) {
      const leftPos = getExactScrollLeftValue(currentLeftPos).value;
      if (carouselPositions.length > 0) {
        if (
          leftPos ===
            carouselPositions[services.length - numOfItemsDisplayed][0] &&
          count === services.length - numOfItemsDisplayed
        ) {
          setIsNextButtonDisabled(true);
        } else {
          setIsNextButtonDisabled(false);
        }

        if (
          (leftPos === carouselPositions[0][0] && count === 0) ||
          leftPos === 0
        ) {
          setIsPrevButtonDisabled(true);
        } else {
          setIsPrevButtonDisabled(false);
        }
      }
    }
  }, [currentLeftPos, count, width, carouselPositions]);

  /** disable button (FOR SINGLE SERVICE PAGE) **/
  useEffect(() => {
    if (isSingleServicePage) {
      if (currentIndex === services.length - 1) {
        setIsNextButtonDisabled(true);
      } else {
        setIsNextButtonDisabled(false);
      }

      if (currentIndex === 0) {
        setIsPrevButtonDisabled(true);
      } else {
        setIsPrevButtonDisabled(false);
      }
    }
  }, [services, currentIndex]);

  /** handlePrevious **/
  const handlePrevious = () => {
    setBtnClicked(true);
    // single service page
    if (isSingleServicePage) {
      setCurrentIndex(currentIndex - 1);
    }
    // services page
    else {
      setCount(count - 1);
    }
  };

  /** handleNext **/
  const handleNext = () => {
    setBtnClicked(true);

    // single service page
    if (isSingleServicePage) {
      setCurrentIndex(currentIndex + 1);
    }
    // services page
    else {
      setCount(count + 1);
    }
  };

  const getExactScrollLeftValue = (value) => {
    for (let i = 0; i < carouselPositions.length; i++) {
      if (
        value > carouselPositions[i][0] - 10 &&
        value < carouselPositions[i][0] + 10
      ) {
        value = carouselPositions[i][0];
        return { value: value, foundValue: true };
      }
    }

    return { value: value, foundValue: false };
  };

  /** set carousel positions **/
  useEffect(() => {
    if (carouselPositions.length === 0) {
      setCarouselPositions(getCarouselPositions);
    }

    // TODO: Add unmount
  }, [services]);

  /** get carousel positions **/
  const getCarouselPositions = () => {
    let newArr = [];
    let negative = 0;
    let w;

    ref.current.querySelectorAll("li").forEach(function (item, i) {
      if (i === 0) {
        w = item.getBoundingClientRect().width;
        setItemWidth(w);
      }

      const offsetLeft = item.getBoundingClientRect().x;
      const offsetRight = offsetLeft + item.getBoundingClientRect().width;
      if (offsetLeft < 0) negative++;

      newArr.push([offsetLeft, offsetRight]);
    });

    newArr.map((n, i) => {
      newArr[i] = [newArr[i][0] + w * negative, newArr[i][1] + w * negative];
    });

    return newArr;
  };

  const serviceListItems = services.map((service) => (
    <ServiceListItem
      key={service.index}
      id={service.id}
      slug={service.attributes.slug}
      order={service.attributes.order}
      title={service.attributes.title}
      description={service.attributes.description}
      icon={service.attributes.icon.data.attributes.url}
      backgroundImage={`${process.env.PUBLIC_URL}/images/${
        service.attributes.order % 4
      }.jpg`}
      mouseEnterHandler={props.mouseEnterHandler}
      mouseLeaveHandler={props.mouseLeaveHandler}
      scroll={props.scroll}
      isSingleServicePage={props.isSingleServicePage}
      x={x}
    />
  ));

  const buttons = (
    <div
      style={{
        opacity: !isSingleServicePage && hideButtons ? "0" : "1",
      }}
    >
      <button
        className={cx([
          "slider-button prev-button",
          "absolute top-[50%] left-[0] translate-y-[-50%]",
          "pl-[10px]",
        ])}
        onClick={handlePrevious}
        disabled={isPrevButtonDisabled}
        style={{
          opacity: isPrevButtonDisabled ? "0.7" : "1",
          cursor: isPrevButtonDisabled ? "auto" : "pointer",
        }}
      ></button>
      <button
        className={cx([
          "slider-button next-button",
          "absolute top-[50%] right-[0] translate-y-[-50%]",
          "pr-[10px]",
        ])}
        onClick={handleNext}
        disabled={isNextButtonDisabled}
        style={{
          opacity: isNextButtonDisabled ? "0.5" : "1",
          cursor: isNextButtonDisabled ? "auto" : "pointer",
        }}
      ></button>
    </div>
  );

  return (
    <React.Fragment>
      <ul
        id="serviceList"
        ref={ref}
        className={cx([
          "service-list scroll-snap-horizontal-container",
          props.isFullScreen ? "full-screen" : "half-screen",
        ])}
      >
        {serviceListItems}
      </ul>
      {buttons}
    </React.Fragment>
  );
};

export default ServiceList;
