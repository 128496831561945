import React, { useState, useEffect, useRef } from "react";

import _ from "lodash";
import cx from "classnames";

import MainHeading from "../MainHeading";

import nextButton from "../../assets/images/next-button2.svg";
import prevButton from "../../assets/images/prev-button2.svg";

import SmallHeading from "../SmallHeading";

const Offices = ({ offices }) => {
  const [selectedOfficeIndex, setSelectedOfficeIndex] = useState(0);
  const [fadeAnime, setFadeAnime] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  const buttonRef = useRef(null);

  const handleOfficeClick = (index) => {
    setFadeAnime(true);

    setTimeout(() => {
      setSelectedOfficeIndex(index);
      setCurrentImageIndex(0);
    }, 200);

    setTimeout(() => {
      setFadeAnime(false);
    }, 500);
  };

  const handlePrevClick = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNextClick = () => {
    const selectedOffice = offices?.office_information[selectedOfficeIndex];
    if (currentImageIndex < selectedOffice.images.data.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  useEffect(() => {
    setPrevButtonDisabled(currentImageIndex === 0);
    setNextButtonDisabled(
      currentImageIndex ===
        offices?.office_information[selectedOfficeIndex].images.data.length - 1
    );
  }, [currentImageIndex, selectedOfficeIndex]);

  useEffect(() => {
    if (offices?.office_information) {
      const updateButtonHeight = () => {
        if (buttonRef.current) {
          const buttonHeight = buttonRef.current.getBoundingClientRect().height;
          document.documentElement.style.setProperty(
            "--button-height",
            `${buttonHeight}px`
          );
        }
      };

      updateButtonHeight();

      window.addEventListener("resize", updateButtonHeight);

      return () => {
        window.removeEventListener("resize", updateButtonHeight);
      };
    }
  }, [offices?.office_information, buttonRef]);

  return (
    <div className="flex flex-col-reverse flex-wrap md:display">
      {/* Left Container */}
      <div
        className={cx([
          "flex items-center justify-center px-[10%] md:px-[none] min-h-[360px] h-[40vh] md:h-auto w-[100vw] md:w-[50%]",
          "relative md:static",
        ])}
      >
        {/* Office Images Gallery */}
        <div
          className={cx([
            "absolute top-0 left-0 w-full md:w-[50%] h-full md:h-[100vh]",
          ])}
        >
          {offices?.office_information.map((office, index) => (
            <div
              key={index}
              className={cx([
                "absolute top-0 left-0 w-full h-full overflow-hidden transition-opacity duration-1000 delay-200",
                index === selectedOfficeIndex ? "fade-in" : "fade-out",
              ])}
            >
              {office?.images?.data.map((data, i) => (
                <div
                  key={i}
                  className={cx([
                    "absolute top-0 left-0 w-full h-full overflow-hidden transition-transform duration-[1s] ease-out",
                  ])}
                  style={{
                    zIndex: 10 - i,
                    clipPath:
                      index === selectedOfficeIndex && i < currentImageIndex
                        ? "inset(0 100% 0 0)"
                        : "inset(0)",
                    transition: "clip-path 1s cubic-bezier(.33,.83,.43,1.03)",
                  }}
                >
                  <img
                    src={data.attributes.url}
                    className={cx([
                      "w-full h-full object-cover transition-transform duration-[1s] ease-out",
                      index === selectedOfficeIndex &&
                        i === currentImageIndex &&
                        "scale-100",
                      index === selectedOfficeIndex &&
                        i < currentImageIndex &&
                        "scale-150",
                      index === selectedOfficeIndex &&
                        i > currentImageIndex &&
                        "scale-150",
                    ])}
                    style={{}}
                  />
                </div>
              ))}
              <div
                className={cx([
                  "w-full h-full",
                  "absolute top-0 left-0",
                  "bg-black opacity-50",
                ])}
                style={{ zIndex: 10 }}
              ></div>
            </div>
          ))}
        </div>
        <div
          className={cx([
            "w-full md:w-[21.5%]",
            "pr-[15%] md:pr-0",
            "relative md:absolute md:top-[50%] md:left-14 md:translate-y-[-50%]",
            fadeAnime ? "fade-out" : "fade-in",
          ])}
          style={{ zIndex: 11 }}
        >
          <h3 className="mb-5">
            {offices?.office_information[selectedOfficeIndex] && (
              <>
                <SmallHeading
                  title={`${offices?.office_information[selectedOfficeIndex].location_city}, ${offices?.office_information[selectedOfficeIndex].location_region_code}`}
                  customStylesOutline="opacity-40 mt-[-5.1vw] sm:mt-[-3.56vw] md:mt-[-2.0675vw] lg:mt-[-29.75px] ml-[-2.5vw] sm:ml-[-1.795vw] md:ml-[-1.04vw] lg:ml-[-15px]"
                />
              </>
            )}
          </h3>
          <div className={cx(["text-white text-sm leading-[1.7]", "mb-5"])}>
            {offices?.office_information[selectedOfficeIndex]?.description}
          </div>
          {offices?.office_information[selectedOfficeIndex]?.images.data
            .length > 1 && (
            <div className="image-gallery-nav">
              <button
                onClick={handlePrevClick}
                disabled={prevButtonDisabled}
                className={cx([
                  "w-10",
                  "mr-4",
                  prevButtonDisabled && "disabled cursor-auto opacity-50",
                ])}
              >
                <img src={prevButton} alt="prev" />
              </button>
              <button
                onClick={handleNextClick}
                disabled={nextButtonDisabled}
                className={cx([
                  "w-10",
                  nextButtonDisabled && "disabled cursor-auto opacity-50",
                ])}
              >
                <img src={nextButton} alt="next" />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className={cx(["page-width", "md:h-[100vh] pt-[25%] md:py-[8vh]"])}>
        <div
          className={cx(
            "flex flex-wrap flex-col-reverse md:flex-row md:flex-nowrap"
          )}
        >
          {/* Right Container */}
          <div className={cx(["w-full md:w-[50%] md:ml-auto", "md:pl-36"])}>
            <MainHeading
              title={_.get(offices, ["headline"], "")}
              medium
              gradientOutline
              gradientPurple={process.env.REACT_APP_IS_EOSSITE === "TRUE"}
              customStylesOutline="text-[#1c3ba1] ml-[-7vw] md:ml-[-3.3vw] lg:ml-[-47.615px] leading-none"
              customStylesTitle="!text-black mt-0 md:mt-[2%]"
            />

            <p
              className={cx([
                "mt-4 mb-7 md:mb-14",
                "leading-[normal] whitespace-pre-wrap",
                "fluid-text-16px leading-[1.5]",
                "relative",
              ])}
            >
              {_.get(offices, ["strapline"], "")}
            </p>

            <div
              className={cx(
                "grid grid-cols-2 md:block",
                "relative",
                "mx-[-12px] md:mx-0 mb-10 md:mb-0"
              )}
            >
              <div
                className={cx(
                  "hidden md:block",
                  "outline-box rounded-lg border border-solid border-opacity-25 border-black transition-all duration-300 w-full absolute left-0"
                )}
                style={{
                  height: "var(--button-height, auto)",
                  top:
                    selectedOfficeIndex *
                      (buttonRef.current?.offsetHeight + 12) || 0,

                  left: 0,
                }}
              ></div>
              <div
                className={cx(
                  "block md:hidden",
                  "outline-box rounded-lg border border-solid border-opacity-25 border-black transition-all duration-300 w-[50%] absolute left-0"
                )}
                style={{
                  height: "var(--button-height, auto)",
                  top:
                    Math.floor(selectedOfficeIndex / 2) *
                      (buttonRef.current?.offsetHeight + 12) || 0,
                  left: selectedOfficeIndex % 2 === 1 ? "50%" : 0,
                }}
              ></div>
              {/* Office Select Buttons */}
              {offices?.office_information.map((office, index) => {
                return (
                  <div
                    key={index}
                    ref={index === 0 ? buttonRef : null}
                    className={cx(
                      "relative w-full",
                      "p-3 mb-[12px]",
                      index === selectedOfficeIndex
                        ? "pointer-events-none cursor-auto"
                        : "cursor-pointer"
                    )}
                    onClick={() => handleOfficeClick(index)}
                  >
                    <div className={cx("block: md:flex")}>
                      <div className={cx("w-full md:w-[35%]", "mb-3 md:mb-0")}>
                        <img
                          className={cx(
                            "office-image-thumbnail",
                            "object-cover"
                          )}
                          style={{ aspectRatio: "1.72" }}
                          src={office?.images?.data[0]?.attributes?.url}
                          alt="Office"
                        />
                      </div>

                      <div
                        className={cx(
                          "w-full: md:w-auto",
                          "md:ml-5 flex flex-col justify-between wrap"
                        )}
                      >
                        <div
                          className={cx(
                            "office-location",
                            "w-full",
                            "font-medium leading-[1.1] mb-3 md:mb-1",
                            "fluid-text-24px"
                          )}
                        >
                          <div className={cx("whitespace-nowrap")}>
                            {office?.location_city}
                            {","}
                          </div>

                          <div className={cx("whitespace-nowrap")}>
                            {office?.location_region}
                          </div>
                        </div>
                        <div
                          className={cx(
                            "whitespace-nowrap text-sm mt-auto leading-none mb-1 md:mb-0"
                          )}
                        >
                          {office?.size}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offices;
